import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"background fill-height d-block",attrs:{"fluid":""}},[_c('u-project-top-nav',{attrs:{"title":_vm.activeProjectName,"extended":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('u-stepper-progression',{attrs:{"step":_vm.stepper.step,"sub-step":_vm.stepper.subSteps,"subtitle":_vm.stepper.subtitle,"title":_vm.stepper.title},on:{"click:prev":function($event){return _vm.$router.push(_vm.stepper.prevLink)},"click:next":function($event){return _vm.$router.push(_vm.stepper.nextLink)}}})]},proxy:true}])}),_c(VContainer,{staticClass:"pb-0",attrs:{"fluid":""}},[_c('u-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('h1',{staticClass:"secondary--text"},[_vm._v("Metric Configuration")])],1),_c(VRow,{staticClass:"content ma-2"},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSheet,{staticClass:"card-border fill-height overflow-hidden",attrs:{"outlined":"","rounded":"lg"}},[_c('u-canvas',{ref:"canvas",attrs:{"action-type":_vm.canvasActionType,"image-url":_vm.activeCamera.camera_image},on:{"updateArea":_vm.updateCanvasArea,"updateMetricArea":_vm.updateCanvasMetricArea}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('u-tabs',{attrs:{"items":_vm.ALL_METRICS,"content-height":"58vh","identifier":"value"},scopedSlots:_vm._u([{key:"tab",fn:function(ref){
var item = ref.item;
var active = ref.active;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.label))]),_c('u-button',{staticClass:"ml-4",attrs:{"color":active ? 'white' : 'gray-7-base',"icon":"","x-small":""},on:{"click":function($event){return _vm.showDeleteMetricModal(item.label)}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-times")])],1)]}},(!_vm.showAllMetrics)?{key:"additional-tabs",fn:function(){return [_c(VTab,[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)]},proxy:true}:null,{key:"tab-item",fn:function(ref){
var item = ref.item;
return [(item.value === 'conflict')?_c('conflict-parameters-card',{model:{value:(_vm.activeConfig),callback:function ($$v) {_vm.activeConfig=$$v},expression:"activeConfig"}}):_c('metric-config-parameters-card',{attrs:{"canvas-ref":_vm.$refs.canvas},on:{"movement-updated":_vm.movementUpdated},model:{value:(_vm.activeConfig),callback:function ($$v) {_vm.activeConfig=$$v},expression:"activeConfig"}})]}},(!_vm.showAllMetrics)?{key:"additional-tab-items",fn:function(){return [_c(VTabItem,[_c(VSheet,{staticClass:"pa-4"},[_c('new-metric-card')],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.activeMetric),callback:function ($$v) {_vm.activeMetric=$$v},expression:"activeMetric"}})],1)],1),_c(VRow,{staticClass:"px-4 ma-1",attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('u-dropdown-alt',{attrs:{"items":_vm.projectCameras,"item-text":"camera_name","large":"","offset-y":"","placeholder":"Select a camera","prepend-inner-icon":"fas fa-video","return-object":"","top":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v("fas fa-video")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.camera_name))])]}}]),model:{value:(_vm.activeCamera),callback:function ($$v) {_vm.activeCamera=$$v},expression:"activeCamera"}})],1),_c(VContainer,{staticClass:"col ma-0 pa-0 d-flex align-center",attrs:{"fluid":""}},[_c('u-preview-toggle-group',{attrs:{"items":_vm.canvasDrawTypes,"item-value":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var item = ref.item;
var index = ref.index;
return [_c(VIcon,{attrs:{"color":active ? 'white' : 'secondary',"large":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(index + 1)+" ")]}}]),model:{value:(_vm.canvasActionType),callback:function ($$v) {_vm.canvasActionType=$$v},expression:"canvasActionType"}})],1),_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text col-auto mr-2",attrs:{"color":"primary","large":""},on:{"click":_vm.saveMetricConfig}},[_vm._v(" Save Config ")]),_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text col-auto",attrs:{"color":"primary","large":""},on:{"click":function($event){_vm.showProcessMetricModel = true}}},[_vm._v(" Get Results ")])],1),_c('u-confirmation-modal',{attrs:{"body":("Are you sure you want to delete " + _vm.deleteMetricName + " for " + (_vm.activeCamera.camera_name) + "? This action cannot be undone."),"title":("Delete " + _vm.deleteMetricName),"action-name":"Delete"},on:{"click:confirmAction":function($event){return _vm.deleteMetric(_vm.deleteMetricName)}},model:{value:(_vm.deleteMetricFlag),callback:function ($$v) {_vm.deleteMetricFlag=$$v},expression:"deleteMetricFlag"}}),_c('process-metric-modal',{attrs:{"processLoader":_vm.processLoader},on:{"click:process":_vm.processMetric},model:{value:(_vm.showProcessMetricModel),callback:function ($$v) {_vm.showProcessMetricModel=$$v},expression:"showProcessMetricModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }