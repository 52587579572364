<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="activeProjectName" extended>
      <template #extension>
        <u-stepper-progression
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Breadcrumbs  -->
    <v-container class="pb-0" fluid>
      <u-breadcrumbs :items="breadcrumbItems" />
      <h1 class="secondary--text">Metric Configuration</h1>
    </v-container>

    <v-row class="content ma-2">
      <!--  Canvas  -->
      <v-col cols="12" md="6">
        <v-sheet
          class="card-border fill-height overflow-hidden"
          outlined
          rounded="lg"
        >
          <u-canvas
            ref="canvas"
            :action-type="canvasActionType"
            :image-url="activeCamera.camera_image"
            @updateArea="updateCanvasArea"
            @updateMetricArea="updateCanvasMetricArea"
          />
        </v-sheet>
      </v-col>

      <!--  Parameters  -->
      <v-col cols="12" md="6">
        <u-tabs
          v-model="activeMetric"
          :items="ALL_METRICS"
          content-height="58vh"
          identifier="value"
        >
          <!--  Custom Tab headers  -->
          <template #tab="{ item, active }">
            <span class="text-capitalize">{{ item.label }}</span>
            <u-button
              :color="active ? 'white' : 'gray-7-base'"
              class="ml-4"
              icon
              x-small
              @click="showDeleteMetricModal(item.label)"
            >
              <v-icon x-small>fas fa-times</v-icon>
            </u-button>
          </template>

          <!--  Add metric header  -->
          <template v-if="!showAllMetrics" #additional-tabs>
            <v-tab>
              <v-icon small>fas fa-plus</v-icon>
            </v-tab>
          </template>

          <!--  Tab Content  -->
          <template #tab-item="{ item }">
            <conflict-parameters-card
              v-if="item.value === 'conflict'"
              v-model="activeConfig"
            />
            <metric-config-parameters-card
              v-else
              v-model="activeConfig"
              :canvas-ref="$refs.canvas"
              @movement-updated="movementUpdated"
            />
          </template>

          <!--  Add Metric tab content  -->
          <template v-if="!showAllMetrics" #additional-tab-items>
            <v-tab-item>
              <v-sheet class="pa-4">
                <new-metric-card />
              </v-sheet>
            </v-tab-item>
          </template>
        </u-tabs>
      </v-col>
    </v-row>

    <!--  Action Buttons  -->
    <v-row align="center" class="px-4 ma-1" justify="space-between">
      <!-- Camera Selector -->
      <v-col cols="auto">
        <u-dropdown-alt
          v-model="activeCamera"
          :items="projectCameras"
          item-text="camera_name"
          large
          offset-y
          placeholder="Select a camera"
          prepend-inner-icon="fas fa-video"
          return-object
          top
        >
          <template #item="{ item }">
            <v-icon>fas fa-video</v-icon>
            <span class="ml-2">{{ item.camera_name }}</span>
          </template>
        </u-dropdown-alt>
      </v-col>

      <!-- Toolbar -->
      <v-container class="col ma-0 pa-0 d-flex align-center" fluid>
        <!--  Canvas Actions  -->
        <u-preview-toggle-group
          v-model="canvasActionType"
          :items="canvasDrawTypes"
          item-value="name"
        >
          <template v-slot="{ active, item, index }">
            <v-icon :color="active ? 'white' : 'secondary'" large>
              {{ item.icon }}
            </v-icon>
            {{ index + 1 }}
          </template>
        </u-preview-toggle-group>
      </v-container>

      <!-- Next Navigation -->
      <u-button
        class="text-capitalize font-weight-bold secondary--text col-auto mr-2"
        color="primary"
        large
        @click="saveMetricConfig"
      >
        Save Config
      </u-button>
      <u-button
        class="text-capitalize font-weight-bold secondary--text col-auto"
        color="primary"
        large
        @click="showProcessMetricModel = true"
      >
        Get Results
      </u-button>
    </v-row>

    <!--  Delete Metric Confirmation Modal  -->
    <u-confirmation-modal
      v-model="deleteMetricFlag"
      :body="`Are you sure you want to delete ${deleteMetricName} for ${activeCamera.camera_name}? This action cannot be undone.`"
      :title="`Delete ${deleteMetricName}`"
      action-name="Delete"
      @click:confirmAction="deleteMetric(deleteMetricName)"
    />
    <process-metric-modal
      v-model="showProcessMetricModel"
      :processLoader="processLoader"
      @click:process="processMetric"
    />
  </v-container>
</template>

<script>
import {
  UBreadcrumbs,
  UButton,
  UCanvas,
  UDropdownAlt,
  UTabs,
} from "@/components/base";
import {
  UPreviewToggleGroup,
  UProjectTopNav,
  UStepperProgression,
} from "@/components/common";
import {
  ConflictParametersCard,
  MetricConfigParametersCard,
  NewMetricCard,
} from "@/components";
import { ProcessMetricModal, UConfirmationModal } from "@/components/modals";

import { CONFIG_STEPPER } from "@/utils/navStepper.data";
import { METRIC_CONFIGURATION_BREADCRUMBS } from "@/utils/breadcrumbs.data";
import ApiService from "@/services/ApiService";
import { ALL_METRICS, CANVAS_ACTIONS } from "@/utils/const";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { beforeWindowUnload } from "@/utils";

export default {
  name: "MetricConfiguration",
  components: {
    UPreviewToggleGroup,
    ConflictParametersCard,
    UDropdownAlt,
    ProcessMetricModal,
    UConfirmationModal,
    UCanvas,
    NewMetricCard,
    MetricConfigParametersCard,
    UTabs,
    UButton,
    UBreadcrumbs,
    UStepperProgression,
    UProjectTopNav,
  },
  created() {
    !this.projectCameras.length &&
      this.$store.dispatch("Project/getProjectCameras", this.activeProjectId);
    this.$store.dispatch("Project/listTags", this.activeProjectId);
    this.$store.dispatch("MetricConfig/getMetricConfig");

    window.addEventListener("beforeunload", beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", beforeWindowUnload);
  },
  data: () => ({
    stepper: CONFIG_STEPPER.metricConfig,
    breadcrumbItems: METRIC_CONFIGURATION_BREADCRUMBS,
    deleteMetricFlag: false,
    deleteMetricName: undefined,
    metricConfigId: undefined,
    showProcessMetricModel: false,

    // Canvas
    canvasActionType: undefined,
    canvasDrawTypes: [
      { icon: "$area", name: CANVAS_ACTIONS.ROI },
      { icon: "$area", name: CANVAS_ACTIONS.METRIC_ROI },
    ],

    // Button loader
    processLoader: false,

    // Temporary
    ALL_METRICS: ALL_METRICS,
    showAllMetrics: true,
  }),
  computed: {
    ...mapGetters("MetricConfig", ["getActiveConfig", "getCameraMetrics"]),
    ...mapGetters("Project", ["activeProjectId", "activeProjectName"]),
    ...mapState("MetricConfig", ["activeTag"]),
    ...mapState("Project", ["projectCameras"]),
    activeCamera: {
      get() {
        return this.$store.state.MetricConfig.activeCamera ?? {};
      },
      set(value) {
        this.SET_ACTIVE_CAMERA(value);
      },
    },
    activeMetric: {
      get() {
        return this.$store.state.MetricConfig.activeMetric ?? "";
      },
      set(value) {
        this.SET_ACTIVE_METRIC(value);
      },
    },
    activeConfig: {
      get() {
        return this.$store.getters["MetricConfig/getActiveConfig"];
      },
      set(value) {
        this.activeCamera &&
          this.activeMetric &&
          this.activeTag &&
          this.updateCurrentConfig({ payload: value });
      },
    },
  },
  watch: {
    activeCamera() {
      this.redrawCanvasBoard();
      this.activeMetric = this.getCameraMetrics[0]?.metric_name;
    },
    activeMetric() {
      this.redrawCanvasBoard();
    },
    activeTag() {
      this.redrawCanvasBoard();
    },
  },
  methods: {
    ...mapMutations("MetricConfig", ["SET_ACTIVE_CAMERA", "SET_ACTIVE_METRIC"]),
    ...mapActions("MetricConfig", [
      "updateCurrentConfig",
      "updateCameraMetrics",
      "deleteConfig",
    ]),
    /**
     * Show delete confirmation modal
     * @param metricName - Metric to be deleted
     */
    showDeleteMetricModal(metricName) {
      this.deleteMetricName = metricName;
      this.deleteMetricFlag = true;
    },

    /**
     * Delete Metric from active camera
     * @param metricName - Metric to be deleted
     */
    async deleteMetric(metricName) {
      this.deleteMetricFlag = false;

      // Delete Metric from Camera
      const cameraMetrics = this.getCameraMetrics.filter(
        (metric) => metric.metric_name !== metricName,
      );
      await this.updateCameraMetrics({ cameraMetrics });

      // Delete Metric from config
      this.deleteConfig({
        cameraId: this.activeCamera.camera_id,
        metric: metricName,
      });
    },

    /**
     * Update Canvas ROI Area
     * @param {Array} area - ROI Points
     */
    updateCanvasArea(area) {
      this.activeCamera.camera_id &&
        this.updateCurrentConfig({ type: "CANVAS_AREA", payload: area });
    },

    /**
     * Update Metric ROI Area
     * @param {Array} area - ROI Points
     */
    updateCanvasMetricArea(area) {
      this.activeCamera.camera_id &&
        this.updateCurrentConfig({ type: "CANVAS_METRIC_AREA", payload: area });
    },

    // Redraw Canvas Board and Annotations
    redrawCanvasBoard() {
      const { canvasArea = [], canvasMetricArea = [] } =
        this.getActiveConfig ?? {};
      this.$refs.canvas.updateAnnotations(
        [],
        canvasArea,
        canvasMetricArea,
        this.activeCamera.camera_image,
      );
    },

    movementUpdated(selectedMovementGroups) {
      this.$refs.canvas.drawMovementArrows(selectedMovementGroups);
    },

    /**
     * Save Metric Configuration
     */
    async saveMetricConfig() {
      const params = {
        config_data: this.generateConfig(
          this.$store.state.MetricConfig.configs,
        ),
      };
      try {
        const res = await ApiService.saveMetricConfig(
          this.activeProjectId,
          params,
        );
        this.metricConfigId = res.data["config_id"];
        await this.$store.dispatch("Notification/addNotification", {
          text: `Saved Metric Configuration!`,
          color: "success",
        });
        await this.$store.dispatch("MetricConfig/getMetricConfig");
      } catch (e) {
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Error saving metric config!",
          color: "error",
        });
      }
    },

    /**
     * Start Process Metric
     * @param {String} startDate
     * @param {String} endDate
     * @param {String} startTime
     * @param {String} endTime
     * @param selectedMetricList
     */
    async processMetric(
      startDate,
      startTime,
      endDate,
      endTime,
      selectedMetricList,
    ) {
      const params = {
        start_time: `${startDate} ${startTime}`,
        end_time: `${endDate} ${endTime}`,
        process_metric_list: this.generateProcessMetricList(selectedMetricList),
      };

      try {
        this.processLoader = true;
        await ApiService.processMetric(this.activeProjectId, params);
        this.processLoader = false;
        await this.$store.dispatch("Notification/addNotification", {
          text: "Initiated Metric Processing!",
          color: "success",
        });
        this.showProcessMetricModel = false;
      } catch (e) {
        this.processLoader = false;
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to process metric!",
          color: "error",
        });
      }
    },

    /**
     * Generate Config as per API format
     *
     * It's going through the nested configs from the store and reconstructing
     * the config object to be saved in the API.
     *
     * @param {Object} configs - Configs
     * @returns {Object} - Config Object to be saved
     */
    generateConfig(configs) {
      const res = {};
      // Iterate over cameras
      Object.entries(configs).forEach(([cameraId, cameraConfig]) => {
        res[cameraId] = {};
        // Iterate over metrics
        Object.entries(cameraConfig).forEach(([metric, metricConfig]) => {
          res[cameraId][metric] = {};
          // Iterate over tags
          Object.entries(metricConfig).forEach(([tagId, tagConfig]) => {
            const { tag_id, tag_name, camera_name, model_path } = tagConfig;

            let config = {
              tag_id,
              tag_name,
              camera_name,
              view: tagConfig.camera_id,
              direction_roi: tagConfig.canvasArea,
              metric_roi: tagConfig.canvasMetricArea.length
                ? tagConfig.canvasMetricArea
                : null,
            };

            if (metric === "conflict") {
              config = {
                ...config,
                ped_conflict_distance_threshold:
                  tagConfig.pedConflictDistanceThreshold,
                vehicle_conflict_distance_threshold:
                  tagConfig.vehicleConflictDistanceThreshold,
              };
            } else {
              const selectedClusters = tagConfig.selectedMovementGroups?.reduce(
                (res, group) => [...res, Number(group.name)],
                [],
              );

              config = {
                ...config,
                model_path,
                selected_clusters: selectedClusters, // TODO: Change the key to `selected_clusters` later
                cluster_size: tagConfig.movementGroups?.length,
                cluster_labels: tagConfig.selectedLabels,
              };
            }

            res[cameraId][metric][tagId] = { ...config };
          });
        });
      });
      return res;
    },

    /**
     * Generate Process Metric List
     * @param {Array} selectedMetricList - Selected Metric List
     * @returns {Array} - Process Metric List
     */
    generateProcessMetricList(selectedMetricList) {
      let processMetricList = {};
      const configJson =
        this.$store.state.MetricConfig.existingMetricConfig?.config_data;

      configJson &&
        selectedMetricList.forEach(({ cameraId, metrics }) => {
          processMetricList[cameraId] = {};

          metrics.forEach((metric) => {
            processMetricList[cameraId][metric.value] = Object.keys(
              configJson[cameraId][metric.value],
            );
          });
        });
      return processMetricList;
    },
  },
};
</script>

<style lang="scss" scoped>
.outline {
  outline: 2px solid var(--v-gray-7-base);
}
</style>
